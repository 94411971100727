import React, { useState } from "react";
import backgroundImage from "../assets/bg-other-lost-nomads.jpeg";
import { useNavigate } from "react-router-dom";

function HomeScreen() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  return (
    <div className="w-screen h-screen bg-center bg-cover bg-no-repeat overflow-y-scroll bg-opacity-50">
      {/* header */}
      <div class="flex z-50 bg-white border-b shadow-sm fixed   w-full p-4 justify-between items-center">
        <div class="flex flex-row items-center justify-center w-screen">
          <a href="/">
            <img
              class="w-32 md:w-44"
              src="https://aylink.ma/logo.png"
              alt=""
              srcset="https://aylink.ma/logo.png"
            />
          </a>
        </div>
      </div>
      <div
        className="w-screen h-full bg-top bg-cover bg-no-repeat overflow-y-scroll bg-opacity-50 mt-12"
        style={{ backgroundImage: "url(" + backgroundImage + ")" }}
      >
        <div className=" container mx-auto h-screen ">
          <div className="flex flex-col justify-center items-center h-screen">
            <div className="bg-white bg-opacity-70 rounded mx-3 my-2 px-5 py-10">
              <div className="text-xl text-[#03023C] text-center  font-bold  ">
                Please enter the password to get your exclusive tickets
              </div>
              <div className="my-5 flex flex-col">
                <input
                  type="password"
                  value={password}
                  onChange={(v) => {
                    setPassword(v.target.value);
                  }}
                  placeholder="Enter the password"
                  className="rounded bg-transparent focus:text-[#03023C] border border-[#03023C] focus:border-[#03023C]  w-full  px-3 py-2 placeholder:text-[#03023C]"
                />
                {passwordError ? (
                  <p className="text-sm text-red-500 my-2 w-">
                    {passwordError}
                  </p>
                ) : null}
              </div>
              <div className=" md:w-1/2 w-full mx-auto">
                <div
                  onClick={() => {
                    setPasswordError("");
                    if (password === "") {
                      setPasswordError(
                        "Please enter the password to get your exclusive tickets"
                      );
                    } else if (password !== "8112336") {
                      setPasswordError(
                        "Please enter the correct password to get your exclusive tickets"
                      );
                    } else {
                      window.location.href =
                        "https://aylink.ma/p/lost-nomads-2024/blind-pass-944020/";
                    }
                  }}
                  className=" text-center bg-[#03023C] rounded text-white font-bold text-xl cursor-pointer px-3 py-2"
                >
                  Apply
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
